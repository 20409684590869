import { TableProps } from 'antd'
import { push } from 'connected-react-router'
import { getQueryParams, setSortOrder } from 'features/cases-management/lib/helpers'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { CaseName } from 'features/cases-management/ui/cases-table/CaseName'
import { DateItem, ROW_HEIGHT } from 'features/cases-management/ui/cases-table/CasesTable'
import {
  EDefectSourceTypes,
  EDefectsSortBy,
  EDefectsTableIcons,
  SlideWithDefect,
} from 'features/defects/types/TDefectsPagination'
import { useSlidesDefects } from 'features/thumbnails/hooks/useSlidesDefects'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { AvatarElement, TextElement, TooltipElement } from 'shared/ui/kit'
import Flex from 'shared/ui/kit/ui/Flex'
import { Column } from 'shared/ui/table'
import { convertToUpperCaseWithUnderscore } from 'shared/ui/table/lib/helpers'
import { useCustomVt, useTableSort } from 'shared/ui/table/lib/hooks'
import { StyledCasesTable, StyledTableWrapper, WrapperResTable } from 'shared/ui/table/ui/Table.styled'
import styled from 'styled-components'
import { DictionaryItem } from 'types/IDictionary'
import { TCasesManagementTabs } from 'types/TTab'
import { roundAreaThreeTwoDecimals } from 'viewer/tools/ui/artefacts/helpers'
import { VtOpts } from 'virtualizedtableforantd4/dist/esm/vt'

type Props = {
  tabType: TCasesManagementTabs
}

const SCROLL_Y = '100%'
/** Дефолтное поле для сортировки */
export const DEFAULT_SORT_FIELD = 'defectDate'
/** Дефолтное направление для сортировки */
export const DEFAULT_SORT_DIRECTION = 'descend'
/** Оффсет количества элементов, где нужно начинать подгрузку следующей страницы */
const NEXT_PAGE_DOWNLOAD_OFFSET = 6
/** Оффсет количества элементов, на которые скролится предыдущая страница после загрузки */
const UP_SCROLL_OFFSET = 3
/** Высота табов */
const TABS_NAV_HEIGHT = 48

const DefectsTable: React.FC<Props> = ({ tabType }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const { menuTab } = useCaseManagementRouteParam()
  const { isCaseRouting } = useSettingsAndUserRoles()
  const { setTabsQueryParams, tabsQueryParams } = useCaseManagementTabContext()
  const { selectedDefectRowKeys, setSelectedDefectRowKeys, setSelectedSlidesWithDefects, tableQuery } =
    useCaseManagementContext()

  const { defectsList, fetchMoreSlides, fetchPreviousPage, hasPreviousPage, isFetching, isLoadingDefects } =
    useSlidesDefects(tabType, tableQuery)

  const { handleSortChange, sortConfig } = useTableSort<SlideWithDefect>({
    field: DEFAULT_SORT_FIELD,
    order: DEFAULT_SORT_DIRECTION,
  })

  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: SlideWithDefect[]) => {
    setSelectedSlidesWithDefects(selectedRows)
    setSelectedDefectRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys: selectedDefectRowKeys,
  }

  const tableContainerRef: RefObject<HTMLDivElement> = useRef(null)
  const [tableBodyHeight, setTableBodyHeight] = useState(0)

  const onTableUpScroll = async () => {
    const tableOverFlowWrapper = document.querySelector('.ant-table-body') as HTMLElement | null
    if (hasPreviousPage) {
      await fetchPreviousPage()
      tableOverFlowWrapper?.scrollTo({ behavior: 'smooth', top: ROW_HEIGHT * UP_SCROLL_OFFSET })
    }
  }

  const onTableScroll: VtOpts['onScroll'] = async ({ isEnd, top }) => {
    if (!isFetching) {
      !top ? onTableUpScroll() : isEnd && fetchMoreSlides()
    }
  }

  const [vt] = useCustomVt(
    { onScroll: onTableScroll, scroll: { y: tableBodyHeight - ROW_HEIGHT * NEXT_PAGE_DOWNLOAD_OFFSET } },
    [isFetching, tableBodyHeight],
  )

  useEffect(() => {
    const resizeHandle = () => {
      const tableBody = tableContainerRef.current?.querySelector('.ant-table-wrapper')
      const localTableBodyHeight = tableBody?.clientHeight
      localTableBodyHeight && setTableBodyHeight(localTableBodyHeight)
    }

    // Начальный расчет и подписка на изменения
    resizeHandle()

    window.addEventListener('resize', resizeHandle)

    return () => {
      window.removeEventListener('resize', resizeHandle)
    }
  }, [])

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const tableBody = document.querySelector('.ant-table-body')
      if (tableBody) {
        const scrollEvent = new Event('scroll', { bubbles: true })
        tableBody.dispatchEvent(scrollEvent)
      }
    })

    const tableWrapper = document.querySelector('.ant-table-wrapper')
    if (tableWrapper) {
      observer.observe(tableWrapper, {
        childList: true,
        subtree: true,
      })
    }

    return () => {
      observer.disconnect()
    }
  }, [defectsList])

  /** Изменение сортировки */
  useEffect(() => {
    setTabsQueryParams((prevState) => ({
      ...prevState,
      [menuTab]: {
        ...prevState[menuTab],
        [tabType]: {
          queryParams: {
            sortBy:
              (sortConfig?.field && convertToUpperCaseWithUnderscore(sortConfig?.field)) ||
              getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting)?.sortBy,
            sortDir:
              (sortConfig?.order && convertToUpperCaseWithUnderscore(sortConfig?.order)) ||
              getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting).sortDir,
          },
        },
      },
    }))
  }, [sortConfig, menuTab, tabType])

  const onClick = (caseId: number, slideId: number) =>
    dispatch(push(`/defects-viewer/${caseId}/${tabType}?slideId=${slideId}`, { from: `${pathname}${search}` }))

  return (
    <StyledTableWrapper ref={tableContainerRef}>
      <WrapperResTable>
        <StyledDefectsTable<FC<TableProps<SlideWithDefect>>>
          scroll={{ y: tableBodyHeight - ROW_HEIGHT - TABS_NAV_HEIGHT || SCROLL_Y }}
          components={vt}
          rowSelection={rowSelection}
          pagination={false}
          tableLayout="fixed"
          showSorterTooltip={false}
          dataSource={defectsList}
          onChange={handleSortChange}
          loading={isLoadingDefects}
          rowKey={({ slide: { slideId } }, index) => (slideId ? `${slideId}-${index}` : `row-${index}`)}
        >
          <Column
            className="has-divider"
            dataIndex="caption"
            key="barcode"
            title={t('Слайд')}
            render={(_: unknown, { caseId, slide }: SlideWithDefect) => (
              <StyledLink ellipsis onClick={() => onClick(caseId, slide.slideId)}>
                {slide.barcode || slide.slideMetadata?.commonMetadata?.caption}
              </StyledLink>
            )}
          />
          <Column
            className="has-divider"
            dataIndex={['slide', 'stain', 'name']}
            key="stain"
            title={t('Окраска')}
            render={(stainName: string) => <TextElement ellipsis>{stainName || t('Неизвестно')}</TextElement>}
          />
          <Column
            className="has-divider"
            dataIndex="caseId"
            key="caseName"
            title={t('Случай')}
            sorter={true}
            sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, EDefectsSortBy.CASE_ID)}
            render={(_: unknown, { caseId, caseName, slideStats }: SlideWithDefect) => (
              <CaseName
                isArchive={false}
                key={caseId}
                countDone={slideStats.slidesCount}
                countTotal={slideStats.referencesCount}
                caseName={caseName}
              />
            )}
          />
          <Column
            className="has-divider"
            dataIndex={'defectsNames'}
            key="defectType"
            title={t('Тип дефекта')}
            render={(_: string, { defects }: SlideWithDefect) => {
              const { commentText, typeText } = defects.reduce(
                (acc, { defectType, qualityControlComment }) => {
                  acc.typeText = defectType?.name
                    ? `${acc.typeText ? acc.typeText + ', ' : ''}${defectType?.name}`
                    : acc.typeText
                  acc.commentText = qualityControlComment
                    ? `${acc.commentText}${acc.commentText ? ', ' : ''}<${qualityControlComment}>`
                    : acc.commentText
                  return acc
                },
                { commentText: '', typeText: '' },
              )

              const renderText = typeText ? `${typeText}${commentText ? '. ' : ''}${commentText}` : ''

              return (
                <TooltipElement title={renderText}>
                  <EllipsisText>{`${renderText || t('Неизвестно')}`}</EllipsisText>
                </TooltipElement>
              )
            }}
          />
          <Column
            className="has-divider"
            dataIndex="tissuePercentage"
            key="tissuePercentage"
            title={t('% от ткани')}
            render={(_: unknown, record: SlideWithDefect) => {
              const totalTissuePercentage = record.defects.reduce(
                (total, defect) => total + (defect.tissuePercentage ?? 0),
                0,
              )
              return (
                <TextElement ellipsis>
                  {totalTissuePercentage > 0 ? roundAreaThreeTwoDecimals(totalTissuePercentage) : '—'}
                </TextElement>
              )
            }}
          />
          <Column
            className="has-divider"
            dataIndex="caseResearchType"
            key="caseResearchType"
            title={t('Тип исследования')}
            render={(caseResearchType: DictionaryItem | null) => (
              <TextElement ellipsis>{caseResearchType?.name || t('Неизвестно')}</TextElement>
            )}
          />
          <Column
            className="has-divider"
            dataIndex="createdBy"
            key="createdBy"
            title={t('Кто обнаружил')}
            render={(_: unknown, record: SlideWithDefect) => {
              const uniqueDefects = record.defects.filter(
                (defect, index, self) =>
                  self.findIndex((d) => d.createdBy?.fullname === defect.createdBy?.fullname) === index,
              )

              return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  {uniqueDefects.map(({ createdBy, defectSource, falsePositive, slideDefectId }) => {
                    const isAiDefect = defectSource === EDefectSourceTypes.ML_ARTEFACTS
                    const isAiFalsePositive = isAiDefect && !!falsePositive
                    const getIconName = () => {
                      if (!isAiDefect) return
                      return falsePositive ? EDefectsTableIcons.MistakeSmall : EDefectsTableIcons.Small
                    }
                    const iconName = getIconName()
                    const text = isAiDefect
                      ? t('Автоматическая детекция')
                      : createdBy?.fullname ?? t('Неизвестный пользователь')
                    return (
                      <Flex align={'center'} gap={8} key={slideDefectId}>
                        {isAiFalsePositive ? (
                          <TooltipElement title={t('Ложная детекция')} placement="top">
                            <StyledAvatarElement
                              AiFalsePositive
                              fullname={createdBy?.fullname}
                              numberValue={createdBy?.userId}
                              iconName={iconName}
                            />
                          </TooltipElement>
                        ) : (
                          <StyledAvatarElement
                            fullname={createdBy?.fullname}
                            numberValue={createdBy?.userId}
                            iconName={iconName}
                          />
                        )}
                        <TextElement ellipsis>{text}</TextElement>
                      </Flex>
                    )
                  })}
                </div>
              )
            }}
          />
          <Column
            className="has-divider"
            dataIndex="defectDate"
            sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, EDefectsSortBy.DEFECT_DATE)}
            sorter={true}
            key="createdAt"
            title={t('Время обнар. дефекта')}
            render={(_: unknown, record: SlideWithDefect) => {
              const oldestDefect = record.defects
                .filter((defect) => defect.createdAt)
                .sort((a, b) => {
                  const dateA = a.createdAt ? new Date(a.createdAt).getTime() : Infinity
                  const dateB = b.createdAt ? new Date(b.createdAt).getTime() : Infinity
                  return dateA - dateB
                })[0] // сортируем по дате и выбираем первый (самый старый)

              return oldestDefect ? <DateItem date={oldestDefect.createdAt} /> : null
            }}
          />
        </StyledDefectsTable>
      </WrapperResTable>
    </StyledTableWrapper>
  )
}

export default DefectsTable

const EllipsisText = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

const StyledLink = styled(TextElement)`
  color: var(--color-blue) !important;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  &:hover {
    text-decoration: underline;
  }
`

const StyledAvatarElement = styled(AvatarElement)<{
  AiFalsePositive?: boolean
}>`
  border: ${({ AiFalsePositive }) => (AiFalsePositive ? '1px solid var(--color-red)' : undefined)};
  flex-shrink: 0;
`
const StyledDefectsTable = styled(StyledCasesTable)`
  && tbody > tr:hover > td {
    cursor: default;
  }
`
